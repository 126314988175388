var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.report
    ? _c("div", { staticClass: "boxed-layout" }, [
        _vm._m(0),
        _vm._v(" "),
        !_vm.isLoading
          ? _c(
              "div",
              [
                _c("ReportCover", {
                  attrs: {
                    maxpages: _vm.maxPages,
                    pagenum: 1,
                    "show-edit": _vm.editMode,
                  },
                }),
                _vm._v(" "),
                _c("ReportOverviewPageV2", {
                  attrs: {
                    maxpages: _vm.maxPages,
                    pagenum: 2,
                    "show-edit": _vm.editMode,
                  },
                }),
                _vm._v(" "),
                _vm._l(_vm.customers, function (c, index) {
                  return _c("ReportDetailPageV2", {
                    key: c.id,
                    attrs: {
                      answers: _vm.findAnswersForCustomer(c),
                      customer: c,
                      maxpages: _vm.maxPages,
                      pagenum: index + 3,
                      "show-edit": _vm.editMode,
                    },
                  })
                }),
              ],
              2
            )
          : _c(
              "div",
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "fab no-print d-flex flex-column align-items-end d-print-none",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mb-1",
                attrs: {
                  title: _vm.editMode ? "Bearbeiten Beenden" : "Bearbeiten",
                  type: "button",
                },
                on: {
                  click: function ($event) {
                    return _vm.toggleEditMode()
                  },
                },
              },
              [
                _vm.editMode
                  ? _c("font-awesome-icon", { attrs: { icon: "times" } })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.editMode
                  ? _c("font-awesome-icon", { attrs: { icon: "pencil-alt" } })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mb-1",
                attrs: {
                  disabled: _vm.editMode || _vm.hasUnsavedChanges,
                  type: "button",
                },
                on: { click: _vm.downloadPDF },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "download" } }),
                _vm._v("   Drucken\n    "),
              ],
              1
            ),
            _vm._v(" "),
            _vm.hasUnsavedChanges
              ? _c(
                  "div",
                  { staticClass: "d-flex flex-column align-items-end" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mb-1",
                        attrs: { disabled: _vm.editMode, type: "button" },
                        on: { click: _vm.storeInCCA },
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "save" } }),
                        _vm._v("   In CCA Aktualisieren\n      "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-right" }, [
                      _c(
                        "span",
                        { staticClass: "text-danger" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "exclamation-triangle" },
                          }),
                          _vm._v(" Nicht gespeicherte Änderungen"),
                        ],
                        1
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _vm.report.updatedAt
                        ? _c("span", [
                            _vm._v(
                              "zuletzt aktualisiert: " +
                                _vm._s(
                                  _vm.formatDateTime(_vm.report.updatedAt)
                                ) +
                                " "
                            ),
                            _c("br"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.report.ccaUpdatedAt
                        ? _c("span", [
                            _vm._v(
                              "\n          zuletzt im CCA hinterlegt: " +
                                _vm._s(
                                  _vm.formatDateTime(_vm.report.ccaUpdatedAt)
                                ) +
                                "\n        "
                            ),
                          ])
                        : _c("span", { staticClass: "text-danger" }, [
                            _vm._v("noch nicht im CCA hinterlegt"),
                          ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "no-print" }, [
      _c("h1", [_vm._v("Report-Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }